import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./Games.css";

function Tables() {
    const [categories, setCategories] = useState([]);
    const [filteredCategories, setFilteredCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState("");
    const [categoryImages, setCategoryImages] = useState({});
    const [sortOption, setSortOption] = useState("name-asc");
    const [showNSFW, setShowNSFW] = useState(false);
    const [steamUser, setSteamUser] = useState(null);
    const [steamLibrary, setSteamLibrary] = useState([]);
    localStorage.setItem("redirectAfterLogin", window.location.pathname); 
    const navigate = useNavigate();

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await axios.get("/api/admin/categories");
                if (response.data && Array.isArray(response.data)) {
                    setCategories(response.data);
    
                    // Fetch images for categories
                    response.data.forEach((category) => {
                        if (category.appid) {
                            fetchCategoryLogo(parseInt(category.appid), category._id);
                        }
                    });
                }
            } catch (error) {
                console.error("Error fetching categories:", error);
            } finally {
                setLoading(false);
            }
        };
    
        const fetchCategoryLogo = async (steamAppId, categoryId) => {
            const imageUrl = `https://shared.fastly.steamstatic.com/store_item_assets/steam/apps/${steamAppId}/header.jpg`;
    
            const img = new Image();
            img.src = imageUrl;
            img.onload = () => {
                setCategoryImages((prev) => ({ ...prev, [categoryId]: imageUrl }));
            };
            img.onerror = () => {
                setCategoryImages((prev) => ({ ...prev, [categoryId]: "default-placeholder.jpg" }));
            };
        };
    
        const fetchSteamUser = async () => {
            try {
                const userResponse = await axios.get("/api/admin/steam/user");
                setSteamUser(userResponse.data);
    
                const libraryResponse = await axios.get("/api/admin/steam/library");
                setSteamLibrary(libraryResponse.data.library);
    
                // Fetch NSFW preferences if logged in
                const preferencesResponse = await axios.get("/api/auth/user/preferences/nsfw");
                if (preferencesResponse.data && preferencesResponse.data.showNSFW !== undefined) {
                    setShowNSFW(preferencesResponse.data.showNSFW);
                    sessionStorage.setItem("showNSFW", preferencesResponse.data.showNSFW);
                }
            } catch (err) {
                console.error("Error fetching Steam user or library:", err);
            }
        };
    
        const fetchNSFWPreference = async () => {
            const token = localStorage.getItem("token"); // Get token from localStorage
            if (token) {
                try {
                    const preferencesResponse = await axios.get("/api/auth/preferences/nsfw", {
                        headers: { Authorization: `Bearer ${token}` },
                    });
                    if (preferencesResponse.data && preferencesResponse.data.showNSFW !== undefined) {
                        setShowNSFW(preferencesResponse.data.showNSFW);
                        sessionStorage.setItem("showNSFW", preferencesResponse.data.showNSFW);
                    }
                } catch (err) {
                    console.error("Error fetching NSFW preference:", err);
                }
            } else {
                // Load NSFW preference from session storage for non-logged-in users
                const savedNSFW = sessionStorage.getItem("showNSFW");
                if (savedNSFW !== null) {
                    setShowNSFW(JSON.parse(savedNSFW));
                }
            }
        };
        
    
        // Execute functions
        fetchCategories();
        fetchSteamUser();
        fetchNSFWPreference();
    }, []);    

    useEffect(() => {
        let filtered = categories.filter((category) =>
            category.name.toLowerCase().includes(searchQuery.toLowerCase())
        );
    
        // Apply NSFW filter
        if (!showNSFW) {
            filtered = filtered.filter((category) => !category.isNSFW);
        }
    
        // Filter by owned games
        if (steamLibrary.length > 0) {
            const ownedAppIds = steamLibrary.map((game) => String(game.appid)); // Ensure IDs are strings
            filtered = filtered.filter((category) => ownedAppIds.includes(String(category.appid))); // Convert category.appid to string
        }
    
        // Sort the filtered categories
        if (sortOption.startsWith("name")) {
            filtered.sort((a, b) =>
                sortOption === "name-asc"
                    ? a.name.localeCompare(b.name)
                    : b.name.localeCompare(a.name)
            );
        } else if (sortOption.startsWith("date")) {
            filtered.sort((a, b) =>
                sortOption === "date-newest"
                    ? new Date(b.createdAt) - new Date(a.createdAt)
                    : new Date(a.createdAt) - new Date(b.createdAt)
            );
        }
    
        setFilteredCategories(filtered);
    }, [categories, searchQuery, sortOption, showNSFW, steamLibrary]);


    // handle category click
    const handleCategoryClick = (category) => {
        const categorySlug = category.slug || category.name.toLowerCase().replace(/\s+/g, "-");
        navigate(`/game/${categorySlug}`);
    };


    //handle steam login
    const handleSteamLogin = () => {
        window.location.href = "/api/admin/auth/steam"; // Redirect to Steam login
    };

    // handle nsfw toggle 
    const handleNSFWToggle = async (isChecked) => {
        setShowNSFW(isChecked);
    
        // Save the state in session storage
        sessionStorage.setItem("showNSFW", isChecked);
    
        const token = localStorage.getItem("token"); // Get token from localStorage
        if (token) {
            try {
                await axios.post(
                    "/api/auth/preferences/nsfw",
                    { showNSFW: isChecked },
                    {
                        headers: { Authorization: `Bearer ${token}` },
                    }
                );
            } catch (error) {
                console.error("Error updating NSFW preference:", error);
            }
        }
    };
    
    
    //handle logout for steam
    const handleLogout = async () => {
        try {
            await axios.get("/api/admin/auth/steam/logout"); // Log out from backend
            setSteamUser(null); // Clear Steam user data
            setSteamLibrary([]); // Clear library
        } catch (err) {
            console.error("Error during logout:", err);
        }
    };

    return (
        <div className="tables-page-container">
            <Helmet>
                <title>Supported Games and Cheat Tables</title>
                <meta
                    name="description"
                    content={categories.map((cat) => `${cat.name} cheat table`).join(", ")}
                />
            </Helmet>

            <h2 className="tables-title">Supported Games</h2>

            {/* Steam Login/Logout Section */}
            <div className="steam-auth-section">
                {steamUser ? (
                    <div className="steam-user-info">
                        <img
                            src={steamUser.avatar}
                            alt="Steam Avatar"
                            className="steam-avatar"
                        />
                        <p>Welcome, {steamUser.username}</p>
                        <button onClick={handleLogout} className="logout-button">
                            Logout
                        </button>
                    </div>
                ) : (
                    <button
                        onClick={handleSteamLogin}
                        className="steam-login-button"
                        title="Sign in with Steam to find cheat tables from your library. No data is stored—everything stays in your session."
                    >
                        <img
                            src="https://upload.wikimedia.org/wikipedia/commons/8/83/Steam_icon_logo.svg"
                            alt="Steam Logo"
                            className="steam-logo"
                        />
                        Sign in with Steam
                    </button>
                )}
            </div>

            <div className="search-sort-container">
                <input
                    type="text"
                    placeholder="Search games..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="search-input"
                />
                <select
                    value={sortOption}
                    onChange={(e) => setSortOption(e.target.value)}
                    className="sort-select"
                >
                    <option value="name-asc">Sort by Name (A-Z)</option>
                    <option value="name-desc">Sort by Name (Z-A)</option>
                    <option value="date-newest">Sort by Date Added (Newest to Oldest)</option>
                    <option value="date-oldest">Sort by Date Added (Oldest to Newest)</option>
                </select>
                    <div className="nsfw-toggle">
                        <label htmlFor="nsfw-filter" className="toggle-label">
                            <span className="toggle-text">{showNSFW ? "NSFW Games" : "NSFW Games"}</span>
                            <div className="toggle-switch">
                                <input
                                    type="checkbox"
                                    id="nsfw-filter"
                                    className="toggle-input"
                                    checked={showNSFW}
                                    onChange={(e) => handleNSFWToggle(e.target.checked)}
                                />
                                <span className="slider"></span>
                            </div>
                        </label>
                    </div>
            </div>

            {loading && <p>Loading categories...</p>}

            <div className="game-grid">
                {filteredCategories.map((category) => (
                    <div
                        key={category._id}
                        className="game-card"
                        onClick={() => handleCategoryClick(category)}
                    >
                        <img
                            src={categoryImages[category._id] || "default-placeholder.jpg"}
                            alt={`${category.name} cheat table`}
                            className="game-image"
                            onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = "default-placeholder.jpg";
                            }}
                        />
                        <div className="game-details">
                            <h3 className="game-name">{category.name}</h3>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Tables;
