// components/Logout.js
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function Logout() {
    const navigate = useNavigate();

    useEffect(() => {
        // Remove the token etc from local storage
        localStorage.removeItem("token");
        localStorage.removeItem("avatar");
        localStorage.removeItem("redirectAfterLogin");
        localStorage.removeItem("RefreshToken");
        localStorage.removeItem("theme");

        // Redirect to the home page
        navigate("/");
    }, [navigate]);

    return null;  // No UI needed, just handle logout and redirect
}

export default Logout;
