import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from "axios";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Home.css";

function Home() {
    const navigate = useNavigate();
    const [recentGames, setRecentGames] = useState([]);
    const [gameImages, setGameImages] = useState({});
    localStorage.setItem("redirectAfterLogin", window.location.pathname);
    const [theme, setTheme] = useState("default");
    const [fallingItems, setFallingItems] = useState([]);
    const [popularGames, setPopularGames] = useState([]);

    // Determine the current theme based on the date
    const determineTheme = () => {
        const now = new Date();
        const month = now.getMonth() + 1; // Months are 0-indexed
        const day = now.getDate();

        if ((month === 10 && day >= 17) || (month === 10 && day <= 31)) {
            return "halloween";
        } else if ((month === 12 && day >= 12) || (month === 1 && day <= 1)) {
            return "newyear";
        } else if ((month === 3 && day >= 18) || (month === 4 && day <= 15)) {
            return "easter";
        } else if ((month === 11 && day >= 7) || (month === 11 && day <= 30)) {
            return "thanksgiving";
        } else {
            return "default";
        }
    };

    // Generate falling items for each theme
    const generateFallingItems = (theme) => {
        const items = [];
        const numberOfItems = 75; // Number of falling items
        let contentOptions = [];
    
        switch (theme) {
            case "halloween":
                contentOptions = ["🎃", "👻", "🦇"];
                break;
            case "newyear":
                contentOptions = ["✻"];
                break;
            case "easter":
                contentOptions = ["🥚", "🐰"];
                break;
            case "thanksgiving":
                contentOptions = ["🍂", "🦃"];
                break;
            default:
                contentOptions = [];
        }
    
        for (let i = 0; i < numberOfItems; i++) {
            items.push({
                id: i,
                content: contentOptions[Math.floor(Math.random() * contentOptions.length)],
                style: {
                    left: `${Math.random() * 100}vw`,
                    animationDuration: `${Math.random() * 8 + 12}s`, // Reduced to 12-20 seconds for slightly faster animations
                    animationDelay: `${Math.random() * -5}s`,
                    fontSize: `${Math.random() * 20 + 10}px`,
                },
            });
        }
    
        setFallingItems(items);
    };
    
    useEffect(() => {
        const fetchPopularGames = async () => {
            try {
                const response = await axios.get("/api/auth/games/popular");
                setPopularGames(response.data);
            } catch (error) {
                console.error("Error fetching popular games:", error);
            }
        };
    
        fetchPopularGames();
    }, []);

    useEffect(() => {
        // Set theme based on date
        const theme = determineTheme();
        setTheme(theme);

        // Generate falling items based on the theme
        generateFallingItems(theme);

        // Fetch recent games
        const fetchRecentGames = async () => {
            try {
                const response = await axios.get("/api/auth/games/recent");
                if (response.data && Array.isArray(response.data)) {
                    setRecentGames(response.data);
                    response.data.forEach((game) => {
                        if (game.appid) {
                            fetchGameImage(game.appid, game.id);
                        }
                    });
                } else {
                    console.error("Fetched games are not an array:", response.data);
                }
            } catch (error) {
                console.error("Error fetching recent games:", error);
            }
        };

        // Fetch game image
        const fetchGameImage = async (steamAppId, gameId) => {
            const imageUrl = `https://shared.fastly.steamstatic.com/store_item_assets/steam/apps/${steamAppId}/header.jpg`;
            const img = new Image();
            img.src = imageUrl;
            img.onload = () => {
                setGameImages((prev) => ({ ...prev, [gameId]: imageUrl }));
            };
            img.onerror = () => {
                setGameImages((prev) => ({ ...prev, [gameId]: "default-placeholder.jpg" }));
            };
        };

        fetchRecentGames();
    }, []);

    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 1200,
        slidesToShow: 5, // Increased number of slides to fit the wider container
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        responsive: [
            {
                breakpoint: 1200, // Medium screens
                settings: {
                    slidesToShow: 4, // Show 4 slides
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 992, // Smaller screens
                settings: {
                    slidesToShow: 3, // Show 3 slides
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 768, // Mobile screens
                settings: {
                    slidesToShow: 1, // Show 1 slide
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <div className={`home-container theme-${theme}`}>
            <Helmet>
                <title>Home - Sintrix.net</title>
            </Helmet>

            <div className="falling-items-container">
                {fallingItems.map((item) => (
                    <div key={item.id} className="falling-item" style={item.style}>
                        {item.content}
                    </div>
                ))}
            </div>

            <div className="home-content">
                <img
                    src="/logo192.png"
                    alt="HomeLogo"
                    className="homelogo"
                    onDoubleClick={() => navigate("/redpill")}
                />
                <h1 className="home-title">Welcome to Sintrix</h1>

                <p className="home-description">
                    A user-friendly platform for Cheat Engine tables. Make and vote on requests, discover public profiles, message others, and report issues to ensure quality.
                </p>
                <div className="home-buttons">
                    <Link to="/requests" className="home-button">
                        Cheat Requests
                    </Link>
                    <Link to="/games" className="home-button">
                        Tables
                    </Link>
                </div>
                <hr className="separator-line" />
                <div className="recent-games-slider">
                    <h2 className="recent-games-title">Most Recent Tables</h2>
                    {Array.isArray(recentGames) && recentGames.length > 0 ? (
                        <Slider {...sliderSettings}>
                            {recentGames.map((game) => (
                                <div key={game._id} className="slider-item">
                                    <Link to={`/game/${game.slug}`}>
                                        <img
                                            src={`https://shared.fastly.steamstatic.com/store_item_assets/steam/apps/${game.appid}/header.jpg`}
                                            alt={`${game.name}`}
                                            className="game-image"
                                            onError={(e) => {
                                                e.target.onerror = null;
                                                e.target.src = "default-placeholder.jpg";
                                            }}
                                        />
                                    </Link>
                                    <h3 className="game-title">{game.name}</h3>
                                </div>
                            ))}
                        </Slider>
                    ) : (
                        <p className="no-games-message">No recent games available.</p>
                    )}
                </div>

                <hr className="separator-line recent-games" />

                <div className="popular-games-slider">
                    <h2 className="popular-games-title">Most Popular Tables</h2>
                    {popularGames.length > 0 ? (
                        <Slider {...sliderSettings}>
                            {popularGames.map((game) => (
                                <div key={game._id} className="slider-item">
                                    <Link to={`/game/${game.slug}`}>
                                        <img
                                            src={`https://shared.fastly.steamstatic.com/store_item_assets/steam/apps/${game.appid}/header.jpg`}
                                            alt={game.name}
                                            className="game-image"
                                            onError={(e) => {
                                                e.target.onerror = null;
                                                e.target.src = "default-placeholder.jpg";
                                            }}
                                        />
                                    </Link>
                                    <h3 className="game-title">{game.name}</h3>
                                </div>
                            ))}
                        </Slider>
                    ) : (
                        <p className="no-games-message">No popular games available.</p>
                    )}
                </div>

            </div>
        </div>
    );
}

export default Home;